@import 'variables.scss';

.Overlay{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: $black;
	opacity: 0;
	pointer-events: none;
	z-index: 20;
	transition: 500ms
}

.Overlay.visible{
	opacity: 0.7;
}

.Overlay.blockclick{
	pointer-events: all;
}