$black: #333;
$white: #fff;
$lightgrey: #f2f2f2;

$transition-metal: #f7f6cc;
$nonmetal: #c0d7f0;
$alkali-metal: #f7aac0;
$alkaline-earth-metal: #ffdca9;
$metalloid: #93d9f5;
$post-transition-metal: #d4ebd8;
$noble-gas: #e1cfe5;
$lanthanide: #fce0ed;
$actinide: #fabfe2;

$linkcolor: #0074D9;