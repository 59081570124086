@import 'variables.scss';

.Minimap{
	position: absolute;
	top: 5.1vw;
	left: 13.1vw;
	z-index: 10;
	background: $white;
	box-shadow: 0vw 1vw 5vw -5vw black;
	padding: 2vw;
	border: 1px solid #f0f0f0;
	
	li{
		float: left;
		font-size: 1vw;
		padding-bottom: 1vw;
		font-family: 'Open Sans', sans-serif;
		white-space: nowrap;
		display: inline-block;
		width: 14vw;

		span{
			margin-right: 0.5vw;
			width: 0.75vw;
			height: 0.75vw;
			display: inline-block;
		}
	}

	li:nth-child(2n+1){
		clear: left;
	}

	li:nth-last-child(1), li:nth-last-child(2){
		padding-bottom: 0;
	}
}

.highlight_active .Minimap li{
	opacity: 0.3;
}

.highlight_active .Minimap li:hover{
	opacity: 1;
}